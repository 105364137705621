
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
 import ContactForm from './dashboard/contactus/ContactFormAdd';
import AdminLogin from './dashboard/login/AdminLogin';
  import DashboardHome from './dashboard/DashboardHome';
import Requests from './dashboard/contactus/Requests';
import BlogsHome from './dashboard/blogs/BlogsHome';
import AddBlog from './dashboard/blogs/AddBlog';
import UpdateBlogPost from './dashboard/blogs/UpdateBlogPost';
 import RecentEnquiries from './dashboard/contactus/RecentEnquiries';
import OtpVerification from './dashboard/login/Otpverification';
import  AuthProvider  from './dashboard/login/AuthContext';
import ProtectedRoute from './dashboard/login/ProtectedRoute';
  function App() {
    const isAuthenticated = !!localStorage.getItem('authToken');

    console.log('Is Authenticated:', isAuthenticated); 
  return (
    <div className='font-poppins  bg-dasOffWhite' >

    <Router>
      
    <Routes> 
    <Route path="/admin-login" element= {<AdminLogin   />} />
    <Route path="/admin-login/c2VyIn0sImlhdCI6MTY4ODY0MTUzOC" element= {<OtpVerification   />} />
   {/** dashboard */} 
   <Route path="/adminpanel" element={isAuthenticated ? <DashboardHome /> : <AdminLogin />} />
    <Route path="/adminpanel/enquiries"element={isAuthenticated ?<Requests  /> : <AdminLogin />} />
   <Route path="/adminpanel/blogs" element={isAuthenticated ? <BlogsHome />: <AdminLogin />} />
   <Route path="/adminpanel/blogs/add-blogpost" element={isAuthenticated ? <AddBlog />: <AdminLogin />}/>
    <Route path="/adminpanel/blogs/:id/edit" element={isAuthenticated ? <UpdateBlogPost />: <AdminLogin />} /> 

     {/**contcat  */} 
     <Route path="/contact-form" element={isAuthenticated ?<ContactForm/> : <AdminLogin />}/>
     <Route path="/contact-form/recent" element={isAuthenticated ?<RecentEnquiries/>: <AdminLogin />} />
     <Route path="/contact-form/add" element={isAuthenticated ? <ContactForm/>: <AdminLogin />} />

       </Routes>
    </Router>
    </div>
  );
}

export default App;
