import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DashNavVertical, Topbar } from '../../components/DashNav'
import img from '../../images/add-blog/one_image_selector.svg'
import img3 from '../../images/add-blog/three_image_selector.svg'
import {BsPlusLg} from 'react-icons/bs'
import { useParams } from 'react-router-dom';
import { BsExclamationTriangle } from "react-icons/bs";


const UpdateBlogPost = ( ) => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [imageSelected, setImageSelected] = useState(false);
  const [imageSelectedthree, setImageSelectedthree] = useState(false);
  const [title, setTitle] = useState('');
  const [mainParagraph, setMainParagraph] = useState('');
  const [subParagraph, setSubParagraph] = useState('');
  const [altText, setAltText] = useState('');
  const [altTextSecondary, setAltTextSecondary] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [metaKeywords, setMetaKeywords] = useState('');
   const [mainBanner, setMainBanner] = useState('');
  const [images, setImages] = useState([]);
  const [sections, setSections] = useState([{ subTitle: '', paragraph: '', sectionImage: '', youtubeLink: '' }]);
  const [redirectTo, setRedirectTo] = useState(null);
  const [mainBannerChanged, setMainBannerChanged] = useState(false);
  const [imagesChanged, setImagesChanged] = useState(false);
  const [sectionsChanged, setSectionsChanged] = useState(false);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);

  const handleClickOpen = () => {
    setRedirectTo("/adminpanel/blogs");
  };
  useEffect(() => {
    
    const fetchPost = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts/${id}`);
        console.log(id); // Log the id here

        setPost(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    
    fetchPost();
  }, [id]);

  // Update state hooks when post data is fetched
  useEffect(() => {
    if (post) {
      setTitle(post.title);
      setMainParagraph(post.mainParagraph);
      setSubParagraph(post.subParagraph);
      setAltText(post.altText);
      setAltTextSecondary(post.altTextSecondary);
      setMetaTitle(post.metaTitle) 
      setMetaDescription(post.metaDescription) 
      setMetaKeywords(post.metaKeywords)
       setMainBanner(post.mainBanner || '');
      setImages(post.images || []);
      if (Array.isArray(post.sections)) {
        // We have to check if there's an image in the sections and keep it if there is
        const sectionsWithImages = post.sections.map(section => ({
          ...section,
          sectionImage: section.sectionImage || '',
          youtubeLink: section.youtubeLink || ''   

        }));
        setSections(sectionsWithImages);
      } else {
        setSections([{ subTitle: '', paragraph: '', sectionImage: '' }]);
      }
    }
  }, [post]);


  if (!post) {
    return <div>Loading...</div>; // or a loading spinner
  }
  const handleChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case 'title':
        setTitle(value);
        break;
      case 'mainParagraph':
        setMainParagraph(value);
        break;
      case 'subParagraph':
        setSubParagraph(value);
        break;
      case 'altText':
        setAltText(value);
        break;
      case 'altTextSecondary':
        setAltTextSecondary(value);
        break;
      case 'metaTitle':
        setMetaTitle(value);
        break;
      case 'metaDescription':
        setMetaDescription(value);
        break;
      case 'metaKeywords':
        setMetaKeywords(value);
        break;
       
        case 'sectionTitle':
          case 'sectionBody':
            handleSectionChange(event, 0);
            break;
       
      default:
        break;
    }
  };
  const handleSectionChange = (event, index) => {
    const newSections = [...sections];
    if (event.target.name === 'sectionTitle') {
      newSections[index].subTitle = event.target.value;
    } else if (event.target.name === 'sectionBody') {
      newSections[index].paragraph = event.target.value;
    } else if (event.target.name === 'youtubeLink') {
      newSections[index].youtubeLink = event.target.value;
    }
    setSections(newSections);
  };


  const handleAddMore = () => {
    setSections([...sections, { subTitle: '', paragraph: '', sectionImage: null }]);
  };

  const handleMainBannerChange = (event) => {
    if (event.target.files[0]) {
      setMainBanner(event.target.files[0]);
      setMainBannerChanged(true);
    } else {
      setMainBannerChanged(false);
    }
  };
  const handleImageChange = (event) => {
    if (event.target.files.length > 0) {
      setImages([...event.target.files]);
      setImagesChanged(true);
    } else {
      setImagesChanged(false);
    }
  };

  const handleSectionImageChange = (event, index) => {
    if (event.target.files[0]) {
      const newSections = [...sections];
      newSections[index].sectionImage = event.target.files[0];
      newSections[index].sectionImageFieldname = `sections[${index}][sectionImage]`;
      setSections(newSections);
      setSectionsChanged(true);
    } else {
      setSectionsChanged(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
  
    if (mainBannerChanged) {
      formData.append('main_banner', mainBanner);
    }
    
    formData.append('title', title);
    formData.append('mainParagraph', mainParagraph);
    formData.append('subParagraph', subParagraph);
    formData.append('altText', altText);
    formData.append('altTextSecondary', altTextSecondary);
    formData.append('metaTitle', metaTitle);
    formData.append('metaDescription', metaDescription);
    formData.append('metaKeywords', metaKeywords);
      
    sections.forEach((section, index) => {
      if (sectionsChanged && section.sectionImage) {
        formData.append(`sections[${index}][sectionImage]`, section.sectionImage);
 
      }
  
      const { sectionImage, ...sectionWithoutImage } = section;
      for (let key in sectionWithoutImage) {
        formData.append(`sections[${index}][${key}]`, sectionWithoutImage[key]);
      }
      formData.append(`sections[${index}][youtubeLink]`, section.youtubeLink);

    });
    
    if (imagesChanged) {
      for (let i = 0; i < images.length; i++) {
        formData.append('images', images[i]);
      }
    }
  
    if (id) {  // id from useParams()
      try {
        await axios.patch(`${process.env.REACT_APP_API_URL}/api/posts/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
    
        alert('Blog Post updated successfully!');
        setTitle('');
        setMainParagraph('');
        setSubParagraph('');
        setMainBanner('');
        setImages([]);
        setAltText('');
        setAltTextSecondary('');
        setMetaTitle('');
        setMetaDescription('');
        setMetaKeywords('');
        setImageSelected(false); 
        setImageSelectedthree(false); 
        setMainBannerChanged(false);
        setImagesChanged(false);
        setSectionsChanged(false);
      } catch (err) {
        console.error(err);
        console.error(err.response.data); 
        alert('Error updating post');
      }
    } else {
      console.error('ID is undefined');
    }
  };
  if (!post) {
    return <div>Loading...</div>;  
  }
   return (
    <div className="h-screen flex overflow-hidden">
    <DashNavVertical/>
    <div className="absolute w-32 lg:w-44  2xl:w-52 2xl:p-5 right-6 2xl:right-16 top-36 border-2 border-dasRed p-3 rounded-xl">
      <div className="flex flex-row justify-between">
      <h4 className='text-dasBlack text-lg font-bold'>Edit mode</h4>
      <h4 className='text-dasRed text-lg font-bold  '><  BsExclamationTriangle /></h4>
      </div>
     
<p className='text-dasRed mt-2'>Attention: Please re-upload the blog post images.</p>
</div>
    <div className="flex flex-col w-full overflow-auto">
        <Topbar/>
                <div className="  overflow-auto">
                    <form  className="h-screen  m-5 " onSubmit={handleSubmit}>
                        {/**Heading */}
                         <div className='w-6/12 mx-auto'>
                        <h1 className='font-bold'>Heading</h1>
                        <input type="text" name="title"   placeholder='Title *' value={title} onChange={handleChange} required
                        className='bg-normalWhite w-full  shadow-md p-4 rounded-lg outline-none'/>
                      <div className="py-5">
                        {!imageSelected && (
                      <img src={img} alt="insert-file" className=' w-full py-4 rounded-lg'/>
                        )}
                        <input 
                          type='file' 
                          id='fileInput' 
                          name="main_banner" 
                          className='bg-normalWhite w-full shadow-md p-4 rounded-lg'
                          required  
                          onChange={handleMainBannerChange}
                        />
                        <h6 className='text-dasGrayDark text-sm p-2'>Recommended image size: 1200px * 700px (ratio 16:9)</h6>
                      </div>
                            <input
                            placeholder="Alt text for main banner *"
                            className="bg-normalWhite w-full  shadow-md p-4 rounded-lg py-4 outline-none"
                            required
                            type="text"
                            name="altText"
                            value={altText}
                            onChange={handleChange} 
                        />  
                        <input type="text" name="metaTitle" value={metaTitle} onChange={handleChange} placeholder='Meta title'  className='bg-normalWhite w-full shadow-md p-4 mt-5 rounded-lg'/>
                        <textarea name="metaDescription" value={metaDescription} onChange={handleChange} placeholder='Meta descrption'  className='bg-normalWhite w-full shadow-md p-4 mt-5 rounded-lg h-28'/>
                        <input type="text" name="metaKeywords" value={metaKeywords} onChange={handleChange} placeholder='Keywords'  className='bg-normalWhite w-full shadow-md p-4 mt-5 rounded-lg'/>
                         </div>
                        {/**body */}
                         <div className='w-6/12 mx-auto pt-8'>
                        <h1 className='font-bold'>Body</h1>
                        <textarea
                        name="mainParagraph"
                        value={mainParagraph}
                        onChange={handleChange}
                        required
                        className='bg-normalWhite w-full h-40 shadow-md p-4 my-4 rounded-lg outline-none'
                        placeholder='Main paragraph *'/>

                        

                      <div className="my-5">
                            {!imageSelectedthree && (
                             <img src={img3} alt="insert-file" className='w-full pb-4 rounded-lg'/>
                            )}
                           <input 
                              type='file' 
                              id='fileInput' 
                              name="images" 
                               multiple 
        
                              onChange={handleImageChange}
                              className='bg-normalWhite w-full shadow-md p-4 rounded-lg outline-none'
                            />
                        <h6 className='text-dasGrayDark text-sm p-2'>Recommended image size: 1080px * 1080px (ratio 1:1)</h6>

                          </div>
                          
                             {
        sections.map((section, index) => (
          <div key={index}>
            <input
              type="text"
              name="sectionTitle"
              placeholder='subtitle'
              className='bg-normalWhite w-full  shadow-md p-4 rounded-lg outline-none'
              value={section.subTitle}
              onChange={(event) => handleSectionChange(event, index)}
            />
            <textarea
              name="sectionBody"
              placeholder='paragraph'
              value={section.paragraph}
              className='bg-normalWhite w-full h-40 shadow-md p-4 my-4 rounded-lg outline-none'
              onChange={(event) => handleSectionChange(event, index)}
            />
        <input
        type="file"
        name={`sections[${index}][sectionImage]`}
        className='bg-normalWhite w-full shadow-md p-4  rounded-lg outline-none'
        onChange={(event) => handleSectionImageChange(event, index)}
      />
      <h6 className='text-dasGrayDark text-sm p-2 mb-4'>Recommended image size: 1200px * 400px (ratio 16:9)</h6>
      <input
            type="text"
            name="youtubeLink"
            placeholder='YouTube link'
            value={section.youtubeLink}
            onChange={(event) => handleSectionChange(event, index)}
            className='bg-normalWhite mb-5 w-full shadow-md p-4 rounded-lg'
          />
      </div>
        ))}
                        <div>
                        <button
                        type="button"
                        className='bg-normalWhite w-full flex flex-row justify-between mt-2   shadow-md p-2 rounded-3xl border-2 border-companygreen'
                        onClick={handleAddMore}
                        >
                        <h6 className="font-bold ps-3 text-companygreen">Add more content</h6>
                        <h1 className="my-auto font-bold text-companygreen"><BsPlusLg/></h1>
                        </button>
                        </div>
                        </div>
                        <div className='py-6'> </div>
                          {/** button */}
                        <div className="fixed right-6 bottom-10 flex flex-row gap-x-3">
                        <button onClick={handleClickOpen}>Cancel</button>
                        <button className='flex flex-row gap-x-2 bg-companygreen p-3 px-6 text-normalWhite rounded-3xl' type="submit">
                        <p> Publish</p>
                        </button>
                        </div>
                        </form>
                        </div>


                        </div>
                        </div>
   )
}

 export default UpdateBlogPost
 