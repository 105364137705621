import React, { useState } from "react";
import axios from "axios";
import './admin-login.css'
import { adminlogin } from "../../data/admin-login";
import imageUrl from '../../images/login/background.jpg'
import logo from '../../images/logo/Trustline_Digital_Logo_black.png'
const AdminLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
        username,
        password,
      });
      if (response.data.success) {
        localStorage.setItem('authToken', response.data.authToken);
        window.location = "/admin-login/c2VyIn0sImlhdCI6MTY4ODY0MTUzOC";
      } else {
        setError("Invalid credentials");
      }
    } catch (error) {
      console.log(error);
      setError("Something went wrong");
    }
  };
  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
  };
  return (
   <>
   {adminlogin.map((item)=>(
    <section className="admin-login " 
    style={{ backgroundImage: `url(${imageUrl})` }}
    >    
    <div className="container  p-3 md:p-5">
    <div className=" login-card p-3 ">  
     <img src={logo} alt="" className="mx-auto pb-3 md:pb-6 xl:w-3/4 2xl:w-2/4"/> 
      <form onSubmit={handleLogin} className="mx-auto">
        <div>
          <input
            type="text"
            id="username"
            className="md:p-4 p-2 md:mb-5 mb-3   sm:w-72"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <input
            type="password"
            placeholder="Password"
            className="md:p-4 p-2 md:mb-5 mb-3  sm:w-72"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
           
        </div>
        <div>
        <input
          type="checkbox"
          id="rememberMe"
          className=" mb-4 me-2  checkbox"
          checked={rememberMe}
          onChange={handleRememberMe}
        />
        <label htmlFor="rememberMe" className="text-white">Remember me</label>
      </div>
        <button type="submit " className="w-full login-button px-5 py-3 text-white bg-companygreen">Login</button>
        {error && <p className="mt-2 bold">{error}</p>}
      </form>
    </div> 
    </div>
    </section>
     ))}
    </>
  );
};
export default AdminLogin;
