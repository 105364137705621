import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BsArrowRightShort, BsPencilSquare  } from "react-icons/bs";
 import { Link } from 'react-router-dom';

const RecentBlogs = () => {
    const [redirectTo, setRedirectTo] = useState(null);
    const [recentPosts, setRecentPosts] = useState([]);

    useEffect(() => {
      const fetchRecentPosts = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts`);
          const sortedPosts = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sorts the posts by date in descending order (most recent first)
          const latestPosts = sortedPosts.slice(0, 3); // Takes the first three posts from the sorted array
          setRecentPosts(latestPosts);
        } catch (error) {
          console.error('Error fetching recent posts:', error);
        }
      };
      fetchRecentPosts();
    }, []);
    

    useEffect(() => {
      if (redirectTo) {
        window.location.href = redirectTo;
      }
    }, [redirectTo]);
    
    const handleClickOpen = () => {
      setRedirectTo("/adminpanel/blogs");
    };


     
  return (
    <div>
           <div className='flex justify-between'>
                            <h2 className='my-auto font-bold'>Blogs</h2>
                            <h2 className='my-auto cursor-pointer' style={{fontSize:"24px"}}  onClick={handleClickOpen}>
                                <BsArrowRightShort/></h2>
                            </div>
                            <div>
                            { recentPosts.length === 0 ? (
                                  <p>No recent blogs.</p>
                               ) : (
                                recentPosts.map((post, index) => (
                     <div className="flex flex-row gap-x-4 my-4 py-2 bg-dasOffWhite" key={index}>
                        <div className="w-3/12">
                        <img src={`${process.env.REACT_APP_API_URL}/${post.mainBanner}`} alt="" className='rounded-lg'/>
                        </div>
                        <div className="w-8/12 flex flex-col my-auto">
                        <h6 className='font-semibold'>{ post.title}</h6>
                        <div className="flex flex-row justify-between">
                        <p className='my-auto text-dasGrayDark'>
                        {new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(post.createdAt))}
</p>
                        <button>
                        <Link to={`/adminpanel/blogs/${post._id}/edit`} className='my-auto'>
                        <BsPencilSquare/>
                        </Link></button>
                        </div>
                        </div>
                        <div>

                        </div>
                     </div>
                     ))
                     )}
                     </div>
    </div>
  )
}

export default RecentBlogs
