import { BsHouse, BsInbox,  BsJournalText,  } from "react-icons/bs";
 export const logo=[{
    logo:"./Images/dashboard/logo-green.png",
    
}]
export const logo2=[{
  logo:"./Images/dashboard/logo-green.png",
}]
export const dashboard_nav=[
    {
  icon:<BsHouse/>,
  text:"Home",
  onclick:"/adminpanel"
},

  {
    icon:<BsInbox/>,
    text:"Requests",
    onclick:"/adminpanel/enquiries"
  },
 
  {
    icon:<BsJournalText/>,
    text:"Blogs",
    onclick:"/adminpanel/blogs"
  },
 
 
]
export const blogs = [ 
    {
      img:"./Images/dashboard/logo-green.png",
p:"loremjndufhsbduhcbdshcvhv"
},
{   
  p:"loremjndufhsbduhcbdshcvhv"
  },
  {    
    p:"loremjndufhsbduhcbdshcvhv"
    },
    {    
      p:"loremjndufhsbduhcbdshcvhv"
      }
]

