import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DashNavVertical, Topbar } from '../../components/DashNav'
import img from '../../images/add-blog/one_image_selector.svg'
import img3 from '../../images/add-blog/three_image_selector.svg'
import {BsPlusLg} from 'react-icons/bs'

const AddBlog = () => {
  const [imageSelected, setImageSelected] = useState(false);
  const [imageSelectedthree, setImageSelectedthree] = useState(false);
  const [title, setTitle] = useState('');
  const [mainParagraph, setMainParagraph] = useState('');
  const [subParagraph, setSubParagraph] = useState('');
  const [altText, setAltText] = useState('');
  const [images, setImages] = useState([]);
  const [altTextSecondary, setAltTextSecondary] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [metaKeywords, setMetaKeywords] = useState('');
   const [mainBanner, setMainBanner] = useState(null);
   const [sections, setSections] = useState([{ subTitle: '', paragraph: '', sectionImage: null, youtubeVideo: '' }]);
   const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);
  const handleClickOpen = () => {
    setRedirectTo("/adminpanel/blogs");
  };
  const handleChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case 'title':
        setTitle(value);
        break;
      case 'mainParagraph':
        setMainParagraph(value);
        break;
      case 'subParagraph':
        setSubParagraph(value);
        break;
      case 'altText':
        setAltText(value);
        break;
      case 'altTextSecondary':
        setAltTextSecondary(value);
        break;
      case 'metaTitle':
        setMetaTitle(value);
        break;
      case 'metaDescription':
        setMetaDescription(value);
        break;
      case 'metaKeywords':
        setMetaKeywords(value);
        break;
        case 'sectionTitle':
            setSections([
              {
                subTitle: value,   // Change this to 'subTitle'
                paragraph: sections[0].paragraph // Preserve the existing value of `paragraph`
              }
            ]);
            break;
        case 'sectionBody':
            setSections([
              {
                subTitle: sections[0].subTitle, // Preserve the existing value of `subTitle`
                paragraph: value  // Change this to 'paragraph'
              }
            ]);
            break;
      default:
        break;
    }
  };
  const handleSectionChange = (event, index) => {
    const newSections = [...sections];
    if (event.target.name === 'sectionTitle') {
      newSections[index].subTitle = event.target.value;
    } else if (event.target.name === 'sectionBody') {
      newSections[index].paragraph = event.target.value;
    } else if (event.target.name === 'youtubeVideo') {
      newSections[index].youtubeVideo = event.target.value;
    }
    setSections(newSections);
  };
  const handleSectionImageChange = (event, index) => {
    const newSections = [...sections];
    newSections[index].sectionImage = event.target.files[0];
    newSections[index].sectionImageFieldname = `sections[${index}][sectionImage]`; // new line
    setSections(newSections);
  };
  const handleAddMore = () => {
    setSections([...sections, { subTitle: '', paragraph: '', sectionImage: null }]);
  };

  const handleMainBannerChange = (event) => {
    const file = event.target.files[0];
    setImageSelected(file !== undefined);  // update imageSelected state
    setMainBanner(file || '');
  };
  const handleImageChange = (event) => {
    setImages([...event.target.files]);
    setImageSelectedthree(event.target.files.length > 0);
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
  
    formData.append('main_banner', mainBanner);
    formData.append('title', title);
    formData.append('mainParagraph', mainParagraph);
    formData.append('subParagraph', subParagraph);
    formData.append('altText', altText);
    formData.append('altTextSecondary', altTextSecondary);
    formData.append('metaTitle', metaTitle);
    formData.append('metaDescription', metaDescription);
    formData.append('metaKeywords', metaKeywords);
 
     for (let i = 0; i < sections.length; i++) {
      if (sections[i].sectionImage) {
        formData.append(`sections[${i}][sectionImage]`, sections[i].sectionImage);
        // Exclude sectionImage from the JSON stringified section
        const {sectionImage, ...sectionWithoutImage} = sections[i];
        formData.append(`sections[${i}]`, JSON.stringify(sectionWithoutImage));
      } else {
        formData.append(`sections[${i}]`, JSON.stringify(sections[i]));
      }
      console.log('Sections:', sections);

    }

    for (let i = 0; i < images.length; i++) {
      formData.append('images', images[i]);
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/posts`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Blog Post created successfully!');
      setTitle('');
      setMainParagraph('');
      setSubParagraph('');
      setMainBanner('');
      setImages([]);
      setAltText('');
      setAltTextSecondary('');
       setMetaTitle('');
      setMetaDescription('');
      setMetaKeywords('');
      setImageSelected(false); 
      setImageSelectedthree(false); 
    }catch (err) {
      console.error(err);
      console.error(err.response.data); // Log the detailed error response from the server
      alert('Error creating post');
    }
  };

  return (
<>
<div className="h-screen flex overflow-hidden">
    <DashNavVertical/>
    <div className="flex flex-col w-full overflow-auto">
        <Topbar/>
                <div className="  overflow-auto">
                    <form  className="h-screen  m-5 " onSubmit={handleSubmit}>
                        {/**Heading */}
                         <div className='w-6/12 mx-auto'>
                        <h1 className='font-bold'>Heading</h1>
                        <input type="text" name="title"   placeholder='Title *' value={title} onChange={handleChange} required
                        className='bg-normalWhite w-full  shadow-md p-4 rounded-lg outline-none'/>
                      <div className="py-5">
                        {!imageSelected && (
                      <img src={img} alt="insert-file" className=' w-full py-4 rounded-lg'/>
                        )}
                        <input 
                          type='file' 
                          id='fileInput' 
                          name="main_banner" 
                          className='bg-normalWhite w-full shadow-md p-4 rounded-lg'
                          required  
                          onChange={handleMainBannerChange}
                        />
                        <h6 className='text-dasGrayDark text-sm p-2'>Recommended image size: 1200px * 700px (ratio 16:9)</h6>
                      </div>
                            <input
                            placeholder="Alt text for main banner *"
                            className="bg-normalWhite w-full  shadow-md p-4 rounded-lg py-4 outline-none"
                            required
                            type="text"
                            name="altText"
                            value={altText}
                            onChange={handleChange} 
                        />  
                        <input type="text" name="metaTitle" value={metaTitle} onChange={handleChange} placeholder='Meta title'  className='bg-normalWhite w-full shadow-md p-4 mt-5 rounded-lg'/>
                        <textarea name="metaDescription" value={metaDescription} onChange={handleChange} placeholder='Meta descrption'  className='bg-normalWhite w-full shadow-md p-4 mt-5 rounded-lg h-28'/>
                        <input type="text" name="metaKeywords" value={metaKeywords} onChange={handleChange} placeholder='Keywords'  className='bg-normalWhite w-full shadow-md p-4 mt-5 rounded-lg'/>
                         </div>
                        {/**body */}
                         <div className='w-6/12 mx-auto pt-8'>
                        <h1 className='font-bold'>Body</h1>
                        <textarea
                        name="mainParagraph"
                        value={mainParagraph}
                        onChange={handleChange}
                        required
                        className='bg-normalWhite w-full h-40 shadow-md p-4 my-4 rounded-lg outline-none'
                        placeholder='Main paragraph *'/>

                   

                      <div className="my-5">
                            {!imageSelectedthree && (
                             <img src={img3} alt="insert-file" className='w-full pb-4 rounded-lg'/>
                            )}
                           <input 
                              type='file' 
                              id='fileInput' 
                              name="images" 
                               multiple 
                                
                              onChange={handleImageChange}
                              className='bg-normalWhite w-full shadow-md p-4 rounded-lg outline-none'
                            />
 
                        <h6 className='text-dasGrayDark text-sm p-2 mb-2'>Choose 3 images for Instagram - Recommended image size: 1080px * 1080px (ratio 1:1)</h6>
                        <input
                            placeholder="Alt text for instagram images"
                            className="bg-normalWhite w-full  shadow-md p-4 rounded-lg py-4 outline-none"
   
                            type="text"
                            name="altTextSecondary"
                            value={altTextSecondary}
                            onChange={handleChange} 
                        />  

                          </div>
                           
                             {
        sections.map((section, index) => (
          <div key={index}>
            <input
              type="text"
              name="sectionTitle"
              placeholder='subtitle'
              className='bg-normalWhite w-full  shadow-md p-4 rounded-lg outline-none'
              value={section.subTitle}
              onChange={(event) => handleSectionChange(event, index)}
            />
            <textarea
              name="sectionBody"
              placeholder='paragraph'
              value={section.paragraph}
              className='bg-normalWhite w-full h-40 shadow-md p-4 my-4 rounded-lg outline-none'
              onChange={(event) => handleSectionChange(event, index)}
            />
        <input
        type="file"
        name={`sections[${index}][sectionImage]`}
        className='bg-normalWhite w-full shadow-md p-4  rounded-lg outline-none'
        onChange={(event) => handleSectionImageChange(event, index)}
      />
      <h6 className='text-dasGrayDark text-sm p-2 mb-4'>Recommended image size: 1200px * 400px (ratio 16:9)</h6>
      <input
          type="text" 
          name="youtubeVideo" 
          value={section.youtubeVideo} 
          onChange={(event) => handleSectionChange(event, index)} 
          placeholder='Youtube link'
          className='bg-normalWhite mb-5 w-full shadow-md p-4 rounded-lg'
        />
          </div>
        ))
      }
                        <div>
                        <button
                        type="button"
                        className='bg-normalWhite w-full flex flex-row justify-between mt-2   shadow-md p-2 rounded-3xl border-2 border-companygreen'
                        onClick={handleAddMore}
                        >
                            <h6 className="font-bold ps-3 text-companygreen">Add more content</h6>
                            <h1 className="my-auto font-bold text-companygreen"><BsPlusLg/></h1>
                        </button>
                        </div>
                        </div>
                        <div className='py-6'> </div>
                          {/** button */}
                        <div className="fixed right-6 bottom-10 flex flex-row gap-x-3">
                        <button onClick={handleClickOpen}>Cancel</button>
                        <button className='flex flex-row gap-x-2 bg-companygreen p-3 px-6 text-normalWhite rounded-3xl' type="submit">
                          <p> Publish</p>
                        </button>
                        </div>

                        </form>
                        
                        </div>
                        </div>
                        </div>
</>
  )
}

export default AddBlog
