import React  from 'react';
import {DashNavVertical, Topbar} from '../components/DashNav'
import RecentEnquiries from './contactus/RecentEnquiries';
import RecentBlogs from './blogs/RecentBlogs';
const DashboardHome = () => {
  return (
<>
<div className="h-screen flex overflow-hidden">
    <DashNavVertical/>
    <div className="flex flex-col w-full overflow-auto">
        <Topbar/>
                <div className="  overflow-auto">
                    <div className="h-screen  m-5 ">
                    <div className='flex flex-row p-4 gap-x-4'>
                        <div className="card box-shadow w-7/12 rounded-lg p-8 bg-normalWhite">
                       <RecentBlogs/>
                         </div>
                        <div className="card box-shadow w-5/12 rounded-lg p-8 bg-normalWhite">
                       <RecentEnquiries/>
                        </div>
                    </div>

                 </div>
            </div>
        </div>
        </div>
</>
)
}

export default DashboardHome
